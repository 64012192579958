
/*****************************************************************************************
*** Customer settings
*****************************************************************************************/

/* Bang Bonsomer Customer Colours
    Dark-blue: #004159;
    light-blue: #009ace;
    Grey: #728295;
*/

.maincolor {
  color: #009ace;
}
.secondarycolor {
  color: #004159;
}
.tertiarycolor {
  color: #728295;
}

.mainbgcolor {
  background-color: #009ace;
}
.secondarybgcolor {
  background-color: #004159;
}
.tertiarybgcolor {
  background-color: #728295;
}

/* Bang Bonsomer "Button" colors */

.ui.button.bb-lblue {
  background-color: #009ace;
  color: #fff;
}



/*****************************************************************************************
*** APP
*****************************************************************************************/

#root, .App {
  height: 100%;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  font-size: 16px;
}

p {
  font-size: 18px;
}

a {
  font-size: 18px;
}

hr {
  height: 3px;
  color: rgba(34,36,38,.15);
  background-color: rgba(34,36,38,.15);
  border: rgba(34,36,38,.15);
}

hr.title {
  margin-bottom: 20px;
}

a.blue .blue {
  color: rgba(0,0,0,.87);
}

.form .field {
  font-size: 18px;
}

.error {
  color: red;
}

/*****************************************************************************************
*** App layout
*****************************************************************************************/

/* Loader */
#loader, #loader > div {
  height: 100%;
}
#loader > div img {
  width: 200px;
  margin-left: -1rem;
  margin-bottom: 1rem;
}
#loader > div .ui.loader {
  position: relative;
  top: 0;
  left: 25%;
  transform: none;
}

/* Header */
.app-header {
  text-align: center;
  height: 60px;
}

/* Top menu / Navigation */
.top-menu .ui.inverted.menu, .ui.menu {
  box-shadow: 3px 3px 13px #959595;
  background-color: #009ace;
}

.top-menu .ui.menu .item::before,
.top-menu .ui.menu .header.item::before {
  background: #009ace;
}

.top-menu .mobile {
  height: 60px;
}

.top-menu .logo {
  margin-right: 50px;
}

.top-menu .item.app-name {
  color: #fff;
}

.top-menu .menu .navlink,
.top-menu .menu .item a,
.top-menu .menu .dropdown,
.top-menu .menu .mobile .item,
.top-menu .menu .item i {
  color: #fff;
}

.menu .navlink:hover,
.menu .item a:hover,
.menu .item:hover i {
  color: #666;
}

.top-menu .ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
  background-color: #fff;
}

.activeLink {
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 4px;
  border-bottom: 1px solid #f72;
}

.logged-user .text, .menu-dropdown .text {
  padding-right: 5px
}

/* Messages component */
.message-container {
  position: relative;
}
.messages {
  margin: 0;
  padding: 0;
}
.messages.active {
  position: absolute;
  z-index: 1000;
  width: 100%;
  background-color: rgba(1, 1, 1, 0.01);
  padding: 0px 25px;
  margin: 0;
  /*border-bottom: 1px solid #e2e2e2;*/
}

.messages.active .ui.message {
  margin: 5px 0;
}

/* Mobile navigation */
.mobile-navigation {
  height: calc(100% - 60px);
}

.mobile-navigation .ui.segment.pushable {
  border: 0;
  border-radius: 0;
}

/* DO NOT REMOVE THESE OR THE MOBILE NAVIGATION BREAKS */
.pushable:not(body).patch { 
  -webkit-transform: unset; transform: unset;
}

.pushable:not(body) .patch.ui.sidebar {
  position: fixed;
}

body > div {
  z-index: 100;
}


/* Left menu */
a {
  color: #004159;
  text-decoration: none;
}

/* Content page */
.page {
  margin-top: 20px;
  margin-bottom: 50px;
  padding-top: 20px;
  padding-bottom: 50px;
}

/* wrap newline */
.wrap-newline {
  white-space: pre-wrap;
}

/*****************************************************************************************
*** App pages 
*****************************************************************************************/

.pagenotfound, .forbidden {
  text-align: center;
}

.pagenotfound p, .forbidden p {
  font-size: 72px;
  font-weight: bold;
}

.fieldcontent {
  margin-bottom: 18px;
}

.ui.form .fieldcontent > .field {
  margin-bottom: 0px;
}



/*****************************************************************************************
*** Component styles
*****************************************************************************************/

/* Loader */
#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}


/* Buttons */
.icon-button {
  background: #fff;
  border: 1px solid #999;
  padding: 10px;
}
.ui.labeled.icon.button, .ui.labeled.icon.buttons .button, .ui.icon.button {
  margin-bottom: 1px;
  margin-top: 2px;
}
.ui.container.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}


/* Checkbox */
.form .ui.disabled.checkbox label {
  opacity: 100;
  color: #000;
}


/* Grid columns without padding */
.ui.grid.nopadding .row { margin: 0 1rem; }
.ui.grid.nopadding .row .column { padding: 0; }

/* compact grid when using compact class */
.ui.grid.compact {
  margin-top: 5px;
  margin-bottom: 5px;
}

.ui.grid.compact > .row {
  padding: 5px;
}

.ui.grid.compact > .row .field .ui.input input{
  padding: 8px;
}

/* Paged table */
.table-wrapper td::first-letter,
.table-wrapper .button::first-letter {
    text-transform: uppercase;
}

.ui.table thead th.sortable-table {
    cursor: pointer;
}

.pagination-container {
    width: 100%;
    margin: 1rem 0 0;
    text-align: center;
}
.pagination-container .top {
    margin: 0 0 1rem 0;
    padding-top: 1rem;
    border-top: 1px solid #eee;
}

.pagination-container a {
  font-size: 14px;
}

.table-wrapper {
  overflow: auto;
}

.table-wrapper .collapsable td:first-child {
    cursor: pointer;
    vertical-align: top;
    font-size: 16px;
    width: 38px;
    color: #2DA96B;
}

.table-wrapper .collapsed td {
    white-space: nowrap;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-wrapper .open .header {
    font-weight: bold;
}

.table-wrapper .open div {
    margin-bottom: 0.5rem;
}    

.table-wrapper .open div :last-child {
    margin-bottom: 0;
}

#root .table-wrapper .management .ui.button {
    margin: 1px 5px 1px 0px;
}

/* PageTitle Component */
.page-title {
  margin-bottom: 20px;
}

/* File dropzone styles */
.container .dropzone.active {
  border-color: #009ace;
}

.container .dropzone.reject {
  border-color: #ff1744;
}

/*
.dropzone.accept {
  border-color: #00e676;
}*/

.container .dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  min-height: 18rem;
  justify-content: center;
};

/* Search, Selection list and selected item */
.ui.attached.header.search-items-header,
.ui.attached.header.selected-item-header {
  background-color: #009ace;
  border: 0;
  color: #fff;
}

.ui.attached.header .select-item-list-header {
  border: 1px solid #e2e2e2;
  color: #fff;
}


/* Selected item component */
.selected-item-error div.accordion  {
  border: 1px solid red;
  padding: 0 5px 5px;
  border-radius: 5px;
}

.selected-item-error.ui.container {
  border: 1px solid red;
  padding: 0 5px 5px;
  border-radius: 5px;
}

.item-selected {
  border-color: #004159 !important;
  box-shadow: 3px 3px 13px #999  !important;
}
.ui.accordion .title:not(.ui).item-selected {
  border-color: #004159 !important;
  box-shadow: 3px 3px 13px #999  !important;
}

.portal-message {
  border: 3px #D03248 solid;
  padding: 10px;
  border-radius: 5px;
}

.portal-message-container-mobile {
  margin-top: -25px;
  margin-bottom: 25px;
}

.portal-message-mobile {
  border: 3px #D03248 solid;
  padding: 5px;
}
